import React, {Component} from "react";
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import strings from "./strings";
import AuthenticationService from './service/AuthenticationService';
import language from "./language";

class Blogs extends Component {

    constructor(props) {
        super(props)

        this.state = {
            list: [],
            show_blog: false,
            id: 0
        }

       const lang =  language()

        this.post = this.post.bind(this)

        this.post(process.env.REACT_APP_API_URL + "/all_blogs/" + process.env.REACT_APP_SITE_NAME + "/" + lang)
    }

    post(url) {
        const headers = {
            "Content-Type": "application/json",
        };

        axios.get(url, headers)
            .then(response => {
                    this.setState({
                        list: response.data,
                    })
                }
            )
            .catch(error => {
                console.log(error)
            });
    }

    render() {

        const id = this.state.id
        if (this.state.show_blog === true) {
            <Navigate to={'blog/' + 99}/>
        }

        const list = this.state.list
        let blog_list = []

        if (list != undefined) {
            blog_list = list.map((item) =>
                <div key={item.id}>
                    <div className='blog_list_item mt-3'>{item.date_string.substring(0, 10)}
                        <Link className='ml-3' to={'/blog/' + item.id}>{item.title}</Link>
                    </div>
                </div>
            );
        }

        return (

            <div className="float-container">
                <div className='float-child-left'>
                    <div className='home-content'>
                        <div>

                            <div className='mt-5'>
                                {blog_list}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="float-child-right">
                         <p className="sidebar-blogs">
                             <img src="https://content.christine.nl/pics/3poezen.jpg" width="300px" vspace="10"/>
                             <img src="https://content.christine.nl/pics/vlekjes.jpg" width="300px" vspace="10"/>
                             <img src="https://content.christine.nl/pics/pengo.jpg" width="300px" vspace="10"/>
                             <img src="https://content.christine.nl/pics/mitnick.jpg" width="300px" vspace="10"/>

                         </p>
                </div>
            </div>
        )

    }
}

export default Blogs
