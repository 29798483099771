import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import Product from './Product';
import About from './About'
import Home from './Home'
import Blog from './Blog'
import Services from './Services'
import Blogs from './Blogs'
import Login from './Login'
import EditBlog from './EditBlog'
import logo from './images/christine.png'
import './App.css';
import './css/bootstrap.css'
import strings from './strings.js'
import language from "./language";

function App() {

    const userLang = language()

    return (
        <Router>
            <div className='container content-header'>
                <div className='jumbotron'>
                    <div className='header-bar header-bar-color'>
                        <table width="100%">
                            <tbody>
                            <tr>
                                <td>
                                    <h1 className='header-title'>{strings.titel}</h1>
                                    <nav className='navbar navbar-expand-lg navbar-light header-text'>
                                        <p className="navbar-nav"><Link to="/">Home</Link></p>
                                        <p className="navbar-nav"><Link to="/product">{strings.Product}</Link></p>
                                        <p className="navbar-nav"><Link to="/services">{strings.Services}</Link></p>
                                        <p className="navbar-nav"><Link to="/blog">Blog</Link></p>
                                        <p className="navbar-nav"><Link to="/about">{strings.About}</Link></p>
                                    </nav>
                                </td>
                                <td>
                                    <img src={logo} className="logo" alt="logo"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='container content'>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/blog/:id" element={<Blog/>}/>
                            <Route path="/blog" element={<Blog/>}/>
                            <Route path="/product" element={<Product/>}/>
                            <Route path="/services" element={<Services/>}/>
                            <Route path="/all_blogs" element={<Blogs/>}/>
                            <Route path="/edit_blog/:id" element={<EditBlog/>}/>
                            <Route path="/edit_blog" element={<EditBlog/>}/>
                            <Route path="/login" element={<Login/>}/>

                        </Routes>
                    </div>
                </div>

            </div>
        </Router>);
}

export default App;
